import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/dueftlogo.png"

const Header = ({ siteTitle }) => (
  <header>
    <div>
    <div style={{padding: `0 1.0875rem`,}}className="intro">
      <Link to="/"><img style={{marginLeft:"",marginTop:"1em",width:100,textAlign:"left",}} className="companyLogo" src={logo} alt="dueft logo"></img></Link>
    </div>
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
