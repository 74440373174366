/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/header"


import "./layout.css"

const Layout = ({ children, customWidth }) => { /* custom width can be set by: customWidth="blogWidth"*/
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    
      <Header siteTitle=""/>
      <div className={customWidth}
        style={{
          margin: `0 auto`,
          maxWidth: "480px",
          padding: `0 1.0875rem 1.45rem`,
        }}
      >

        <main>{children}</main>
        <footer className="footer" style={{
          marginTop: `2rem`, textAlign: "center", textDecoration: "none"
        }}>
          © {new Date().getFullYear()}, 
          {` `}
          <a href="https://www.veriott.com">veriott.</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
