import React, { useState } from "react";
import { setSessionPassword } from "@mkitio/gatsby-theme-password-protect/src/utils/utils";
import Layout from "../../../components/layout"

const styles = {
  wrapper: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    padding: "0 15px",
    textAlign: "center",
  },
  wrapperInner: {
    width: "300px",
  },
  input: {
    textAlign:"center",
    width: "100%",
    height: "48px",
    borderRadius: "4px",
  },
};

const PasswordProtect = () => {
  const [password, setPassword] = useState("");
  const [isButtonHovered, buttonHover] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
      <form onSubmit={onSubmit}>
        <div style={styles.wrapper}>
          <div style={styles.wrapperInner}>
            <h1 style={{marginTop: 0 }}>Welcome Ms.</h1>
            <h4 style={{ color: "#6f6f6f" }}>
              Great opportunities await behind this great firewall🔥
            </h4>
            <input
              placeholder=" your key here, please"
              name="password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              style={styles.input}
            />

            <button
              type="submit"
              style={{
                ...styles.button,
                ...(isButtonHovered ? styles.buttonHover : null), marginTop:"1em",
              }}
              onMouseEnter={() => buttonHover(true)}
              onMouseLeave={() => buttonHover(false)}
            >
              Get Access
            </button>
          </div>
        </div>
      </form>
  );
};

export default PasswordProtect;
